<template>
  <component
    :is="tag"
    class="relative block xxs:min-w-64"
    :class="{
      'cursor-pointer': hasLink,
      'xl:aspect-service-box-big': props.image?.src && props.variant !== 'simple',
      'h-auto xl:aspect-service-box-simple xl:h-full': props.variant === 'simple',
      'md:rounded-3xl': props.variant === 'simple',
    }"
    :to="props.button?.link"
    :external="props.button?.target === '_blank'"
    :target="props.button?.target"
    data-el="ui-service-box"
    :data-variant="`ui-service-box--${props.variant}`"
  >
    <figure class="group relative flex h-full flex-col justify-end">
      <span
        class="absolute left-0 top-0 z-[-1] size-full opacity-0 xl:z-0"
        :class="[props.overlayClass, {
          'transition-opacity': hasLink,
          'group-hover:opacity-100': hasLink,
          'motion-reduce:transition-none': hasLink,
          'hidden md:rounded-3xl xl:block': props.variant === 'simple',
        }]"
      />
      <component
        :is="imageTag"
        v-if="props.variant !== 'simple' && props.image?.src"
        class="hidden size-full object-cover group-hover:mix-blend-multiply xl:block"
        v-bind="props.image"
        loading="lazy"
        fetchpriority="low"
        width="316"
        height="576"
      />
      <component
        :is="imageTag"
        v-if="props.variant !== 'simple' && props.imageMobile?.src"
        class="size-full object-cover object-top group-hover:mix-blend-multiply xl:hidden"
        v-bind="props.imageMobile"
        loading="lazy"
        fetchpriority="low"
        width="260"
        height="233"
      />
      <div
        class="bottom-0 flex w-full flex-1 flex-col bg-white p-4 xl:absolute xl:h-72 xl:p-6"
        :class="{
          'transition-colors': hasLink,
          'group-hover:bg-transparent': hasLink,
          'motion-reduce:transition-none': hasLink,
          'p-7 group-hover:bg-white md:rounded-3xl xl:p-7 xl:group-hover:bg-transparent': props.variant === 'simple',
        }"
      >
        <div class="mb-4 flex items-center">
          <UiIcon
            v-if="props.icon"
            :name="props.icon"
            :width="64"
            :height="62"
            class="mr-7 h-auto w-12 shrink-0 text-primary xl:w-16"
            :class="{
              'transition-colors': hasLink,
              'group-hover:text-white': hasLink && props.variant !== 'simple',
              'motion-reduce:transition-none': hasLink,
              'w-16 xl:group-hover:text-white': props.variant === 'simple',
            }"
          />
          <p
            data-el="ui-service-box-title"
            class="text-lg font-bold text-primary xl:text-xl"
            :class="{
              'transition-colors': hasLink,
              'line-clamp-2 h-16 overflow-hidden text-ellipsis break-words group-hover:text-white': hasLink && props.variant !== 'simple',
              'motion-reduce:transition-none': hasLink,
              'text-xl xl:group-hover:text-white': props.variant === 'simple',
            }"
          >
            {{ props.title }}
          </p>
        </div>
        <HtmlParser
          v-if="props?.subtitle"
          class="flex-1 text-base font-normal text-primary"
          :class="{
            'transition-colors': hasLink,
            'group-hover:text-white': hasLink && props.variant !== 'simple',
            'motion-reduce:transition-none': hasLink,
            'line-clamp-3 !flex-none overflow-hidden text-ellipsis lg:!flex-1 xl:line-clamp-4': props.variant !== 'simple',
            'md:text-blue xl:group-hover:text-white': props.variant === 'simple',
          }"
          :html="props.subtitle"
        />
        <div
          v-if="hasLink && props.button?.btnText"
          class="mt-5"
        >
          <UiButton
            class="group-hover:border-white group-hover:text-white"
            :class="{ 'mb-3 mt-5 px-3.5 xl:my-0': props.variant === 'simple' }"
            :variant="props.variant === 'simple' ? 'secondary' : 'circle'"
            icon-right="arrow"
            with-animation
            :full-width="props.variant === 'simple' ? true : false"
            :slim="props.variant === 'simple' ? true : false"
          >
            {{ props.button?.btnText }}
          </UiButton>
        </div>
      </div>
    </figure>
  </component>
</template>

<script setup lang="ts">
import { computed, resolveComponent } from 'vue'
import UiButton from '../UiButton/UiButton.vue'
import UiIcon from '../UiIcon/UiIcon.vue'
import type { ImageInterface } from '../UiImage/UiImage.types'
import type { UiButtonType } from './UiServiceBox.types'

interface UiServiceBox {
  image?: ImageInterface | null
  imageMobile?: ImageInterface | null
  icon?: string | null
  title: string
  subtitle?: string
  button: UiButtonType
  variant?: 'default' | 'simple'
  overlayClass?: string
  imageTag?: string
}

const props = withDefaults(defineProps<UiServiceBox>(), {
  variant: 'default',
  image: null,
  imageMobile: null,
  icon: null,
  subtitle: '',
  overlayClass: 'bg-primary',
  imageTag: 'UiImage',
})
const hasLink = computed(() => !!props.button?.link)
const imageTag = computed(() => {
  return resolveComponent(props.imageTag)
})
const tag = computed(() => {
  return hasLink.value ? resolveComponent('nuxt-link') : 'div'
})
</script>

<style lang="postcss">
[data-el='ui-service-box'] {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}

[data-variant='ui-service-box--simple'] {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  [data-variant='ui-service-box--simple'] {
    box-shadow: 0px 4px 20px 0px rgba(70, 129, 244, 0.15);
  }
}

@media (min-width: 1024px) {
  [data-variant='ui-service-box--default'] {
    [data-el='ui-service-box-title'] {
      height: 70px;
    }
  }
}

@media (min-width: 1366px) {
  [data-variant='ui-service-box--simple'] {
    max-width: 304px;
  }
}
</style>
